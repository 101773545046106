<template>
    <v-card>
        <v-card-title class="text-h5">
        {{ $t('cancelPayment') }}
        </v-card-title>

        <v-card-text>
        {{ $t('youSureYouWantToCancelThisPayment') }}
        </v-card-text>

        <v-card-actions>
        <v-spacer />

        <v-btn
            color="red"
            text
            @click="() => $emit('close')"
        >
            {{ $t('no') }}
        </v-btn>

        <v-btn
            color="green"
            text
            @click="handleCallback"
            :disabled="updating"
            :loading="updating"
        >
            {{ $t('yes') }}
        </v-btn>
        </v-card-actions>
    </v-card>
</template>


<script>

export default {
    data() {
        return {
            updating: false
        }
    },
    props: {
        payment: {
            type: Object,
            default: () => null
        }
    },
    methods: {
        handleCallback() {
            if(typeof this.payment === 'undefined' || this.payment === null || typeof this.payment.uuid === 'undefined' || this.payment.uuid === null) {
                return
            }
            this.updating = true
            this.$store
              .dispatch('cancelPayment', this.payment.uuid)
               .then((result) => {
                    if (result.status === 'failure') {
                        this.$store.commit('updateActionSuccess', {
                            message: 'Success',
                            subMessage: 'Payment successfully cancelled'
                        })
                    }else {
                        this.$store.commit('updateActionError', {
                            message: 'Error',
                            subMessage: result.message
                        })
                    }
                })
                .catch(() => {
                    this.$store.commit('updateActionError', {
                        message: 'Error',
                        subMessage: 'Payment not cancelled due to unknown error.'
                    })
                })
                .finally(() => {
                    this.updating = false   
                    this.$emit('close')
                })
        }
    }
}

</script>